@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.market {
  display: flex;
  font-size: 12px;
  align-items: center;
  min-height: 45px;
  margin: 0 auto;
  text-transform: uppercase;
  width: 100%;
  @include device-desktop {
    justify-content: center;
  }
}

.active {
  font-weight: bold;
}
