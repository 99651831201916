@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/palette';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: overlay;
  padding: 0 16px;

  @include device-desktop {
    padding: 0;
  }

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & [class*='swiper-container'] {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: visible;
    position: relative;
  }

  & [class*='swiper-wrapper'] {
    display: flex;
  }

  & [class*='swiper-slide'] > div {
    padding: 0 5px;

    @include device-desktop {
      padding: 0 1px;
    }
  }

  & [class*='swiper-pagination-clickable'] {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 10px;
    min-height: 10px;
    margin-top: 14px;
    align-items: center;
    position: absolute;
    bottom: calc(100% + 18px);

    @include device-desktop {
      width: auto;
      right: 40px;
      margin-top: 0;
      bottom: calc(100% + 34px);
    }

    & [class*='swiper-pagination-bullet'] {
      @include text-hidden;

      height: 2px;
      width: 15px;
      background-color: #c0c0c0;
      margin: 0 3px;
    }

    & [class*='swiper-pagination-bullet-active'] {
      @include text-hidden;

      background-color: $color-black;
    }
  }

  & [class*='swiper-button-prev'] {
    height: 30px;
    width: 30px;
    background-size: 30px;
    background-repeat: no-repeat;
    padding: 30px;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.5 17.5l-17-17M33.5 17.5l-17 17M.5 17.5h33' transform='rotate(180, 17.5, 17.5)' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  }
  & [class*='swiper-button-next'] {
    height: 30px;
    background-size: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.5 17.5l-17-17M33.5 17.5l-17 17M.5 17.5h33' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.header {
  font-family: $font-secondary;
  width: 100%;
  padding: 28px 0 34px;
  margin: 0;
  text-align: center;

  @include device-desktop {
    padding: 25px 0;
  }
}
