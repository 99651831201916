@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.container {
  width: 100%;
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include device-desktop {
    flex-direction: row;
  }
}

.header {
  font-family: $font-primary;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.03em;
  margin: 23px 32px 34px;

  @include device-desktop {
    font-size: 25px;
    line-height: 30px;
    margin: 30px 32px 40px;
  }
}

.slider {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  & [class*='swiper-container'] {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  & [class*='swiper-wrapper'] {
    order: 1;
    display: flex;
  }
}
