@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 9px 0 13px;

  @include device-desktop {
    margin: 0;
  }
}

.title {
  font-family: $font-primary;
  color: $color-black;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin: 0;

  @include device-desktop {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 1px;
  }
}

.description {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: $color-gray-900;

  @include device-desktop {
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.5px;
  }
}
