@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/palette';

.hr {
  border-color: 0 solid $grey-light-2;
  background-color: $grey-light-2;
  color: $grey-light-2;
  height: 1px;
  margin: 30px 0;
}
