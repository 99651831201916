@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 620px;

  & > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.container {
  width: 100%;
  position: relative;
  padding: 30px 0 30px 30px;

  a {
    text-decoration: none;
  }

  @include device-desktop {
    padding: 0 0 0 40px;
  }

  .content + & {
    padding: 30px 30px 30px 0;

    @include device-desktop {
      padding: 0 40px 0 0;
    }
  }
}

.containerLink {
  display: block;
  cursor: pointer;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
}

.contentPadding {
  padding: 30px;

  @include device-desktop {
    padding: 40px;
  }
}

.image {
  height: 100%;
}
