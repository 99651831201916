@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';

.container {
  display: flex;
  justify-content: center;
  padding: 0;
  background-color: $color-black;
  & > div {
    margin: 10px auto;
  }

  @include device-desktop {
    background-color: transparent;
    & > div {
      margin: 25px auto;
    }
  }
}
