@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.wrapper {
  position: absolute;
  z-index: 10;
  right: 20px;
  bottom: 20px;

  @include device-tablet {
    right: 40px;
    bottom: 40px;
  }

  a {
    background-color: $color-white;
    color: $black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    overflow: hidden;
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    position: relative;
    text-decoration: none;

    @include device-tablet {
      width: 290px;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover .hoverBar {
      transform: translateX(4px);
    }

    &:hover .productInfo {
      transform: translateX(4px);
    }

    .hoverBar {
      backface-visibility: hidden;
      background-color: $black;
      position: absolute;
      top: 0;
      height: 100%;
      width: 4px;
      left: -4px;
      transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0ms;
    }
    .productInfo {
      margin-right: 20px;
      transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0ms;

      .name {
        font-size: 13px;
        line-height: 120%;
        font-weight: 600;
        display: block;
        margin-bottom: 7px;
      }

      .price {
        font-size: 10px;
        line-height: 120%;
        font-weight: 400;
        display: block;
      }
    }
    .thumb {
      width: 60px;
      height: 60px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.heroWrapper {
  position: absolute;
  z-index: 10;
  left: 30px;
  top: 95px;

  @include device-mobile-large {
    left: unset;
    top: unset;
    right: 20px;
    bottom: 20px;
  }

  @include device-tablet {
    left: unset;
    top: unset;
    right: 40px;
    bottom: 40px;
  }

  a {
    background-color: $color-transparent;
    padding: 0;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    overflow: hidden;
    text-decoration: none;
    position: relative;
    text-decoration: none;

    @include device-mobile-large {
      background-color: $color-white;
      padding: 5px 5px 5px 20px;
      color: $black;
    }

    @include device-tablet {
      width: 290px;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover .hoverBar {
      transform: translateX(4px);
    }

    &:hover .productInfo {
      transform: translateX(4px);
    }

    .hoverBar {
      backface-visibility: hidden;
      background-color: $black;
      position: absolute;
      top: 0;
      height: 100%;
      width: 4px;
      left: -4px;
      transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0ms;
    }
    .productInfo {
      margin-right: 20px;
      transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0ms;

      .name {
        font-size: 11px;
        line-height: 120%;
        font-weight: 600;
        display: block;
        margin-bottom: 7px;

        @include device-mobile-large {
          font-size: 13px;
        }
      }

      .price {
        color: $color-white;
        font-size: 10px;
        line-height: 120%;
        font-weight: 400;
        display: block;

        @include device-mobile-large {
          color: $font-primary-color;
        }
      }
    }
    .thumb {
      display: none;
      width: 60px;
      height: 60px;

      @include device-mobile-large {
        display: block;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}
