@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/animations';

.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  opacity: 1;
  background-color: black;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.done {
  animation: fade-out-loading 0.2s linear forwards;
}

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

.header {
  font-family: $font-primary;
  margin-top: 42px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
