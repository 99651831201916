@import 'assets/styles/mixins';

.wrapper {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: 0.25s;
}
