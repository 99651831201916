@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';

.wrapper {
  display: flex;
  flex-direction: row;
  color: $color-white;
}

.photo {
  width: 50%;
  & > img {
    width: 100%;
    height: 100%;
  }
}

.header {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  font-family: $font-secondary;
  padding-bottom: 32px;
  margin: 0;

  @include device-desktop {
    font-size: 25px;
    line-height: 30px;
    padding: 30px 0 56px;
  }
}

.form {
  width: 100%;
  margin: 0 auto;
  padding: 36px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include device-tablet {
    padding: 36px 0 66px;
    width: 50%;
    max-width: 410px;
  }
}

.input {
  appearance: none;
  border-radius: 0;
  margin: 0 auto;
  height: 40px;
  font-size: 13px;
  padding: 9px 20px;
  width: 100%;
  max-width: 410px;
  color: $color-black;

  & + input {
    margin-top: 16px;
    @include device-desktop {
      margin-top: 24px;
    }
  }

  &::placeholder {
    color: $color-gray-900;
  }
}

.textarea {
  appearance: none;
  border-radius: 0;
  margin: 16px auto 0;
  height: 160px;
  font-size: 13px;
  padding: 9px 20px;
  width: 100%;
  max-width: 410px;
  resize: none;
  color: $color-black;

  &::placeholder {
    color: $color-gray-900;
  }
}

.consent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
  font-size: 13px;
  justify-content: center;

  @include device-desktop {
    padding-top: 16px;
    justify-content: flex-start;
  }

  & > .checkbox {
    appearance: none;
    border-radius: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $color-white;
    margin-right: 14px;
    outline: none;
    position: relative;

    &.active {
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: $color-white;
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }
  }

  & p {
    margin: 0;
  }
}

.submitButton {
  display: flex;
  color: $color-white;
  background: none;
  border: none;
  outline: none;
  padding-top: 22px;
  justify-content: center;

  &:focus {
    outline: 0;
  }

  @include device-desktop {
    padding-top: 36px;
    justify-content: flex-start;
  }
}

.message {
  padding: 2px 20px 8px;
  font-size: 12px;
  margin: 0 auto;
  min-height: 28px;
  @include device-desktop {
    margin: 0;
  }
}

.consentMessage {
  font-size: 12px;
  margin: 0 auto;
  min-height: 28px;

  @include device-desktop {
    padding-left: 34px;
    margin: 0;
  }
}

.richContent {
  margin: 0 auto;

  @include device-desktop {
    margin: 0;
  }
}
