@import '../../assets/styles/extends';

.wrapper {
  width: 100%;
  padding: 20px 30px;

  @include device-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  & > * {
    flex: 1 1 25%;
  }
}

.vertical {
  display: flex;
  flex-direction: column;

  @include device-desktop {
    flex-direction: row;
  }
}

.horizontal {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px 30px;

  @include device-desktop {
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 40px 0;
  }
}
