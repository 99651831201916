@import 'assets/styles/extends';
@import 'assets/styles/palette';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header {
  font-family: $font-secondary;
  padding: 16px 20px;
  margin: 0;
  font-size: 16px;
  text-align: left;
  width: 100%;
}

.grid {
  width: 100%;
  display: grid;
  margin: 0 20px;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @include device-tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include device-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}
