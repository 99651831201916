@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';
@import 'assets/styles/animations';

.wrapper {
  flex-direction: column;
  width: 100%;

  @include device-desktop {
    justify-content: center;
    align-items: center;
  }
}

.container {
  position: relative;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.454545px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 10px;

  max-width: $layout-max-width;

  @include device-desktop {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    align-items: center;
    margin: 0 0 0 auto;
  }
}

.filter {
  background-color: $color-transparent;
  color: $black;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  z-index: 1;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.454545px;
  display: flex;
  align-items: center;
  width: 100%;

  &:focus,
  &:active {
    outline: none;
  }

  @include device-desktop {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: auto;
  }

  &.searchFilter {
    top: -10px;
  }
}

.categories {
  display: none;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @include device-desktop {
    height: 50px;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 10px;
    overflow-x: hidden;
    width: 100%;
    max-width: $layout-max-width;
    margin: 0 auto;
  }
}

.link {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  @include device-desktop {
    padding: 0 25px;
  }

  &:last-child {
    padding-right: 40px;
  }

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    @include device-desktop {
      margin-left: 5px;
    }
  }
}

.category {
  position: relative;
  display: inline-block;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.454545px;
  text-transform: uppercase;

  @include device-desktop {
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  &.active {
    @include device-desktop {
      font-weight: 600;
    }
  }
}

.subcategories {
  position: relative;
  display: flex;
  flex-direction: row;

  @include device-desktop {
    justify-content: center;
  }
}

.subcategory {
  @include scale-tile;

  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-right: 1px;
  height: 75px;
  cursor: pointer;

  &:last-child {
    padding-right: 0;
  }

  & + & {
    border-left: 1px solid $color-transparent;

    @include device-desktop {
      border-left: 1px solid $color-transparent;
    }
  }

  @include device-desktop {
    padding-right: 0;
    max-width: 120px;
    height: 80px;
  }

  & > a {
    display: flex;
    color: $color-black;
    font-size: 10px;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    height: 75px;
    text-decoration: none;

    @include device-desktop {
      text-transform: none;
      flex-direction: row;
      height: 80px;
    }

    & > svg {
      height: 32px;
      max-width: 32px;

      @include device-desktop {
        height: 40px;
        max-width: 40px;
      }
    }

    & > span {
      position: relative;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.454545px;
      color: $color-black;
      margin-top: 3px;

      @include device-desktop {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-top: 0;
      }
    }
  }

  &.active {
    & span {
      font-weight: 600;
    }
  }
}

.apply {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -58px;
  left: 0;
  right: 0;
  z-index: 1;
  height: 50px;
  background-color: $color-gray-900;
  color: $color-white;
  width: 100%;
  border: none;
  box-shadow: none;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;

  &:focus,
  &:active {
    outline: none;
  }

  @include device-desktop {
    display: none !important;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
}

.applyVisible {
  display: flex;

  &[class*='fade-appear'] {
    opacity: 0;
    transition: opacity 0.2s linear;
    object-fit: cover;
  }

  &[class*='fade-appear-done'] {
    opacity: 1;
  }
}

.subcategoriesWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include device-desktop {
    flex-direction: row;
  }
}
