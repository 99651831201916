@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.wrapper {
  width: 100%;
}

.container {
  width: 100%;
  padding: 40px 23px;
  max-width: 840px;
  margin: 0 auto;
}

.headerText {
  font-family: $font-secondary;
  margin: 0;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.1em;
  padding-bottom: 24px;
  text-transform: uppercase;

  @include device-desktop {
    font-size: 50px;
    line-height: 55px;
    padding-bottom: 22px;
    letter-spacing: 0.2em;
  }
}

.contentText > [class*='RichTextParagraph'] {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.08em;
  font-family: $font-primary;
  max-width: 840px;
  margin: 0 auto;

  @include device-desktop {
    font-size: 18px;
    line-height: 30px !important;
  }
}

.contentText > [class*='RichTextParagraph'] + [class*='RichTextParagraph'] {
  padding-top: 24px;

  @include device-desktop {
    padding-top: 31px;
  }
}
