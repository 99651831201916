@import './assets/styles/palette';

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.code {
  background-color: $grey-light;
  color: $red;
  font-family:
    Consolas,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono;
}

.bold {
  font-weight: 900;
}
