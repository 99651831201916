@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.textClassName {
  padding-right: 36px;
  padding-left: 36px;

  h1 {
    line-height: 120%;
    font-family: $font-secondary;
  }

  h2 {
    font-family: $font-primary;
    line-height: 120%;
    text-transform: uppercase;
  }

  h3 {
    font-family: $font-secondary;
    padding-bottom: 20px;
    line-height: 120%;
    margin: 0;
  }

  button {
    text-decoration: none;
    background-color: $color-transparent;
    margin-right: 34px;
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #cacaca;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.doubleTextClassName {
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;

  @include device-desktop {
    padding-left: 114px;
    padding-right: 114px;
  }

  h1 {
    font-family: $font-secondary;
    line-height: 120%;
  }

  p {
    font-weight: normal;
    line-height: 120%;
  }
}

.doubleImageClassName {
  flex-direction: column;
  width: 100%;

  @include device-desktop {
    flex-direction: row;
    margin: 0 auto;
  }
}

.singleImageInDoubleClassName {
  height: auto;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;

  @include device-desktop {
    height: auto;
    width: 50%;
    &:first-of-type {
      padding-left: 114px;
    }
    &:last-of-type {
      margin-left: 24px;
      padding-right: 114px;
    }
  }
  a {
    text-transform: uppercase;
  }
}

.singleImageInDoubleHeroClassName {
  height: auto;
  padding: 0;
  width: 100%;
  @include device-desktop {
    height: auto;
    width: 50%;
  }
  a {
    text-transform: uppercase;
  }
}

.singleImageClassName {
  height: auto;

  @include device-desktop {
    height: auto;
  }

  div {
    justify-content: center;
    display: flex;
  }

  a {
    font-weight: normal;
    font-size: 24px;
    line-height: 28.8px;
    width: 65%;

    @include device-desktop {
      font-size: 74px;
      line-height: 68px;
    }
  }
}

.singleImageHeroClassName {
  padding: 0;
  height: auto;
  width: 100%;

  @include device-desktop {
    height: auto;
  }

  div {
    justify-content: center;
  }

  video {
    width: 100%;
  }

  iframe {
    width: 100%;
  }

  a {
    font-weight: normal;
    width: 65%;
  }
}

.textWithMediaClassName {
  padding-left: 24px;
  padding-right: 24px;
  height: auto;

  @include device-tablet {
    padding-left: 114px;
    padding-right: 114px;
  }

  h1 {
    font-family: $font-secondary;
    line-height: 25px;
    font-size: 30px;
    @include device-desktop {
      line-height: 72px;
      font-size: 60px;
    }
  }

  p {
    font-size: 16px;
    margin: 0;

    @include device-tablet {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.tileMenuClassName {
  padding-top: 94px;

  @include device-desktop {
    padding-top: 157px;
  }
}
