@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/animations';

.content {
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  padding-bottom: 0;
}

.wrapper {
  flex: 0 0 auto;
}

.dark {
  & .title,
  & .link,
  & .link a,
  & .description {
    color: $color-black;
  }
}

.light {
  & .title,
  & .link,
  & .link a,
  & .description {
    color: $color-white;
  }
}

.big {
  & .wrapper {
    max-width: auto;

    @include device-desktop {
      max-width: 820px;
    }
  }

  & .title {
    font-family: $font-secondary;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;

    @include device-desktop {
      text-transform: uppercase;
      font-family: $font-secondary;
      font-size: 18px;
      line-height: 24px;
    }
  }

  & .description {
    @include description-break;

    display: none;

    @include device-desktop {
      display: block;
    }
  }

  & .link {
    @include scale-link-arrow;

    position: absolute;
    bottom: 30px;
    right: 30px;
    display: none;

    @include device-desktop {
      display: block;
      bottom: 50px;
      right: 50px;
    }

    & svg {
      width: 20px;
      height: 20px;

      @include device-desktop {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@include device-mobile-and-tablet {
  .mobile_top {
    justify-content: flex-start;
  }

  .mobile_middle {
    justify-content: center;
  }

  .mobile_bottom {
    justify-content: flex-end;
  }

  .mobile_left {
    align-items: flex-start;
  }

  .mobile_center {
    align-items: center;
    text-align: center;
  }

  .mobile_right {
    align-items: flex-end;
    text-align: right;
  }
}

@include device-desktop {
  .desktop_top {
    justify-content: flex-start;
  }

  .desktop_middle {
    justify-content: center;
  }

  .desktop_bottom {
    justify-content: flex-end;
    padding-bottom: 4rem;
  }

  .desktop_left {
    align-items: flex-start;
  }

  .desktop_center {
    align-items: center;
    text-align: center;
  }

  .desktop_right {
    align-items: flex-end;
    text-align: right;
  }
}
