@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';

.wrapper {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.element {
  color: $color-black;

  &::before {
    content: '/';
    display: inline-block;
    margin: 0 8px;
    color: currentColor;
  }

  &.noFirst::before {
    display: none;
  }
}

.white * {
  color: $color-white;
}

.black * {
  color: $color-black;
}
