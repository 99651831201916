@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.container {
  width: 100%;
  position: relative;
  padding: 32px 0 0;
  background-color: $color-white;

  @include device-desktop {
    padding: 12px 0 0;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 27px;
  margin: 20px 0;

  @include device-desktop {
    flex-direction: row;
    padding-bottom: 17px;
    margin: 40px 0;
  }

  & [class*='swiper-container'] {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  & [class*='swiper-wrapper'] {
    order: 1;
    display: flex;
  }

  & [class*='swiper-pagination-clickable'] {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 10px;
    min-height: 10px;
    margin-top: 14px;
    align-items: center;

    & [class*='swiper-pagination-bullet'] {
      @include text-hidden;

      height: 2px;
      width: 15px;
      background-color: #c0c0c0;
      margin: 0 3px;
    }

    & [class*='swiper-pagination-bullet-active'] {
      @include text-hidden;

      background-color: $color-black;
    }
  }
}

.content {
  max-width: 192px;
  margin: 0 auto;

  @include device-desktop {
    max-width: 186px;
  }
}

.title {
  font-family: $font-secondary;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: $color-gray-900;
  margin: 0;
  text-transform: uppercase;

  @include device-desktop {
    font-size: 13px;
    line-height: 25px;
  }
}

.description {
  text-align: center;
  margin: 6px 0 0;
  color: $color-black;

  &:empty {
    display: none;
  }

  & > p {
    margin: 0;
    padding: 0;
    letter-spacing: 0.08em;
    font-family: $font-primary;
    font-size: 10px;
    line-height: 18px;
    @include device-desktop {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.05em;
      padding: 2px 0 0;
    }
  }
}
