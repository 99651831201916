$font-main: 'Neue Haas Unica', 'Times New Roman';
$font-main-color: #000000;

$layout-max-width: 1680px;
$minicart-footer-height: 289px;
$minicart-header-height: 120px;
$minicart-main-height: calc(100vh - 289px - 120px);

$header-height: 147px;
$mobile-header-height: 74px;

$siteGutter: 1rem; // TODO delete this
$siteGutterDesktop: 1.5rem; // TODO delete this

// Font size desktop: 12px and 16px
$font-normal-tablet: 11px;
$font-small-desktop: 12px;
$font-medium-desktop: 14px;
$font-big-desktop: 16px;
$font-huge-desktop: 20px;

// Font size mobile: 10px and 12px
$font-small-mobile: 10px;
$font-big-mobile: 12px;

// line height desktop:
$line-height-small-desktop: 15px;
$line-height-medium-desktop: 18px;
$line-height-big-desktop: 20px;
$line-height-huge-desktop: 27px;

// line height mobile:
$line-height-small-mobile: 12px;
$line-height-big-mobile: 15px;
$line-height-very-big-mobile: 28px;

//padding for galleries
$big-padding-desktop: 144px;
$small-padding-desktop: 72px;

$big-padding-mobile: 48px;
$small-padding-mobile: 24px;
