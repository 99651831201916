@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';

.container {
  display: flex;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  text-transform: uppercase;
}
