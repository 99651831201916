@import 'assets/styles/variables';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/animations';

.wrapper {
  position: absolute;
  top: calc(100% + 58px);
  left: 0;
  right: 0;
  width: 100%;
  z-index: 11;
  background-color: white;
  box-shadow:
    0 40px 30px 0 rgb(0, 0, 0, 0.2),
    0 -1px 1px 0 #f1f1f1;

  &[class*='fade-appear'] {
    opacity: 0;
    transition: opacity 0.2s linear;
    object-fit: cover;
  }

  &[class*='fade-appear-done'] {
    opacity: 1;
  }

  @include device-desktop {
    top: calc(100% + 16px);
    min-height: 237px;
  }

  &.searchFilter {
    top: 116px;

    @include device-desktop {
      top: 112px;
    }
  }
}

.content {
  margin: 0 auto;
  max-width: $layout-max-width;
  background-color: $color-white;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 45px 30px 0;

  @include device-desktop {
    padding: 40px 108px 33px;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.column {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;

  & + & {
    border-top: 1px solid $color-black;
  }

  @include device-desktop {
    flex: 0 1 20%;
    padding: 0 32px;

    &[id='filter-sizes'] {
      flex: 0 1 40%;
    }

    & + & {
      border-top: none;
      border-left: 1px solid #f1f1f1;
    }
  }
}

.label {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: $color-gray-900;
  padding: 20px 0 19px;
  background-image: url('/images/icons/icon_plus.svg') !important;
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: 12px;

  @include device-desktop {
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.5px;
    padding: 0;
    background-image: none !important;
  }
}

.list {
  margin: 0;
  padding: 0;
  display: none;
  flex-wrap: wrap;
  list-style: none;
  opacity: 0;
  height: 0;

  @include device-desktop {
    display: flex;
    height: auto;
    opacity: 1;
  }
}

.expanded {
  background-image: url('/images/icons/icon_minus.svg') !important;
}

.expanded + .list {
  display: flex;
  animation: reveal-list 0.3s linear forwards;
}

.item {
  text-align: center;
  height: 25px;
  min-width: 20px;
  margin-right: 20px;
  margin-bottom: 25px;
  cursor: pointer;
}

.selected {
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
    content: '';
  }
}

.text {
  font-size: 12px;
  line-height: 25px;
  text-transform: uppercase;

  @include device-desktop {
    font-size: 13px;
    line-height: 25px;
  }

  &.selected::after {
    bottom: -2px;
  }
}

.color {
  font-size: 12px;
  line-height: 25px;
  height: 20px;
  width: 20px;

  &.selected::after {
    bottom: -7px;
  }

  &[data-tooltip] {
    position: relative;
    word-break: keep-all;
    white-space: nowrap;

    &::after {
      position: absolute;
      text-transform: uppercase;
      opacity: 0;
      pointer-events: none;
      content: attr(data-tooltip);
      left: 0;
      top: calc(100% + 10px);
      background-color: $color-gray-100;
      box-shadow:
        0 2px 2px 0 rgb(0, 0, 0, 0.2),
        0 -1px 1px 0 #f1f1f1;
      z-index: 10;
      padding: 8px;
      transform: translateY(-20px);
      transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &:hover::after {
      opacity: 1;
      transform: translateY(0);
      transition-duration: 300ms;
    }
  }
}

.reset {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: $color-white;
  color: $color-black;
  width: 100%;
  border: none;
  box-shadow: none;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;

  &:focus,
  &:active {
    outline: none;
  }

  @include device-desktop {
    width: auto;
    flex: 0 0 auto;
    padding: 0 40px;
  }
}

.apply {
  display: none;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: $color-white;
  background-color: $color-black;
  border: none;
  box-shadow: none;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 40px;

  @include device-desktop {
    display: flex;
  }
}
