@import 'assets/styles/extends';
@import 'assets/styles/palette';

.wrapper {
  position: relative;
  min-height: 375px;
}

.noSwiperProductsWrapper {
  display: flex;
  flex-wrap: wrap;

  .imageWrapper {
    width: 50%;
    @include device-desktop {
      width: calc(100% / 3);
    }
  }
}

.products {
  width: auto;
}

.imageWrapper {
  flex-shrink: 0;
  position: relative;
  transition-property: transform;
  display: flex;
  align-items: center;
  touch-action: auto;
  user-select: none;
  height: 100%;

  & > div {
    flex: 1;

    @include device-desktop {
      flex: 1;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
}
