@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.productItemClassName {
  a {
    background-color: $color-transparent;
    div[class*='ProductItemImage'] {
      div[class*='noSwiperProductsWrapper'] & {
        padding-bottom: 0;
      }
    }
  }

  div[class*='ProductItemInfo'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    font-size: 10px;

    @include device-desktop {
      font-size: 18px;
      line-height: 20px;
    }

    div[class*='noSwiperProductsWrapper'] & {
      padding: 0;
    }
  }

  div[class*='Accordion_inner'] {
    & > .youMayAlsoLikeClassName {
      margin: 0;
      padding: 0;

      & div[class*='YouMayAlsoLike_noSwiperProductsWrapper'] {
        margin: 0 -6px;
      }

      & div[class*='YouMayAlsoLike_imageWrapper'] {
        padding: 0 6px 22px;
      }
    }
  }
}

.youMayAlsoLikeClassName {
  padding: 0 14px;

  @include device-tablet {
    padding: 0 36px;
  }

  div[class*='Header'] {
    width: 100%;
    display: flex;
    justify-content: center;

    @include device-tablet {
      justify-content: flex-start;
    }

    h3 {
      font-family: $font-secondary;
      font-size: 22px;
      line-height: 26.4px;
      margin-bottom: 24px;

      @include device-desktop {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 68px;
      }
    }
  }

  div[class*='products'] {
    margin: 0;
  }
}
