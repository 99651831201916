@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.wrapper {
  height: 60px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 35;

  @include device-desktop {
    display: none;
  }
}

.button {
  @include reset-button;
  color: $black;

  width: 100%;
  height: 100%;
  cursor: pointer;

  & > svg {
    width: 15px;
    height: 15px;
  }
}
