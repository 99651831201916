@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/animations';

.wrapper {
  width: 100%;
  scroll-margin: 60px 0 0;

  &:not(.noAnimate) {
    animation: block-slide-down 0.2s ease-out forwards;
  }
}

.content {
  position: relative;
  margin: 0 auto;
}

.fullscreen {
  max-width: 100vw;
}

.container {
  max-width: $layout-max-width;
}

.light {
  background-color: $color-white;
  color: $color-black;
}

.dark {
  background-color: $color-black;
  color: $color-white;
}
