@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.wrapper {
  width: 50px;
  height: 60px;

  @include device-desktop {
    width: auto;
    height: 50px;
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 22.5px 21px;

  @include device-desktop {
    padding: 15px 0;
  }
}

.description {
  display: none;
  margin-left: 10px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $color-gray-900;

  @include device-desktop {
    display: flex;
  }
}
