@import 'assets/styles/animations';

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.path {
  stroke: #a5a5a5;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
