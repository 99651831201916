@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.stickyHeaderClassName {
  max-width: 100vw;
  height: $header-height-mobile;
  display: flex;
  align-items: center;
  color: black;
  transition: all 0.2s linear;

  @include device-desktop {
    height: $header-height-desktop;
  }
}

.content {
  width: 100%;
  max-width: $layout-max-width;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  height: 100%;
  padding: 0 10px;

  & img {
    display: block;
  }

  @include device-desktop {
    padding: 0 40px;
  }
}

.backgroundOnHover {
  &:hover {
    @apply bg-white;
  }
}

.transparentHeader {
  @apply bg-transparent;
}

.solidHeader {
  @apply bg-white;
}

.selectionContent {
}

.nav {
  display: flex;
  justify-content: flex-start;
  flex: 1 0 auto;
}

.logo {
  display: flex;
  align-items: center;
  width: 92px;
  height: 60px;
  margin-left: 10px;
  margin-right: auto;

  &:hover {
    color: inherit;
  }

  & > svg {
    margin: 12px 0 19px;
  }

  @include device-desktop {
    margin-left: 0;
    margin-right: 0;
    width: 132px;
    height: 50px;
  }
}

.infoWrapper {
  width: 50px;
  padding: 18px 20px 18px 15px;

  @include device-desktop {
    width: 98px;
    padding: 40px 36px;
  }
}
