@import './variables';
@import './mixins';
@import 'assets/styles/variables';

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.resetButton {
  margin: 0;
  padding: 0;
  background: transparent;
  width: auto;
  overflow: visible;
  border: none;
  min-width: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: inherit;
  -webkit-appearance: none;
  vertical-align: middle;
  cursor: pointer;
  background: none;
}

.container {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: $siteGutter;
  padding-right: $siteGutter;

  @include device-desktop {
    padding-left: $siteGutterDesktop;
    padding-right: $siteGutterDesktop;
  }
}

.article {
  max-width: 50rem;
  margin: 1rem auto;

  @include device-desktop {
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 3rem;
    margin-bottom: 1rem;

    @include device-desktop {
      line-height: 3.6rem;
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.7em;
    margin-bottom: 3rem;
    color: $font-main-color;

    @include device-desktop {
      font-size: 1.25rem;
      line-height: 1.72em;
    }
  }

  h1 {
    font-family: $font-secondary;
    font-size: 2.25rem;

    @include device-desktop {
      font-size: 2.875rem;
    }
  }

  h2 {
    font-family: $font-primary;
    font-size: 2rem;
  }

  h3 {
    font-family: $font-secondary;
    font-size: 1.5rem;
    line-height: 1.3;

    @include device-desktop {
      font-size: 1.875rem;
    }
  }

  h4 {
    font-family: $font-secondary;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}
