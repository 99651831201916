@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/palette';
@import 'assets/styles/animations';

.item {
  display: block;
  transform: scale(0.9);
  transition: all 0.2s ease-in;
  animation: enter-product-item 0.2s ease-out forwards;
  overflow: hidden;
  @for $i from 1 through 12 {
    &:nth-child(#{$i}n) {
      animation-delay: #{$i * 50}ms;
    }
  }
}

//keep any styles in this class or else the class is undefined
.inactive {
  display: block;
}

.link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: $color-black;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background-color: $color-white;

  @include device-desktop {
    display: flex;
    flex-direction: column;
  }
}

.bottom {
  width: 100%;
  padding: 6px;

  @include device-desktop {
    padding: 14px;
  }
}

.bottomInner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  min-height: 11px;

  @include device-desktop {
    min-height: 25px;
  }
}

.labels {
  display: flex;

  img {
    height: 9px;
    margin-right: 6px;
    filter: grayscale(100%);

    @include device-desktop {
      height: auto;
      margin-right: 10px;
    }
  }
}

.labelText {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;

  @include device-tablet {
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
  }
}
.name {
  flex: 2;
}

.variantName {
  flex: 1;
  text-align: end;
}

.soldOut,
.sale {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.32px;
  position: absolute;
  top: 10px;
  right: 11px;
  color: #949494;
  text-transform: uppercase;
  writing-mode: vertical-rl;

  @include device-desktop {
    top: 20px;
    right: 20px;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.45px;
  }
}

.sale {
  color: #e7422c;
}

.outOfStock {
  color: #949494;
}
