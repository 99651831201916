@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/palette';

.paragraph {
  width: 100%;

  a {
    text-decoration: underline;
  }
}

.withSpace {
  & + & {
    padding-top: 13px;
  }
}
