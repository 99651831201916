@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.container {
  width: 100%;
  position: relative;

  &.right:nth-child(1) {
    position: absolute;
    bottom: 0;
    width: 136px;
    height: 136px;
    z-index: 1;
    left: calc(50% - 67px);

    @include device-desktop {
      position: static;
      padding: 40px;
      align-self: center;
      flex: 0 0 30%;
      max-width: 30%;
      height: 100%;
    }
  }

  &.right:nth-child(2) {
    @include device-desktop {
      padding: 40px 0;
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  &.left {
    @include device-desktop {
      padding: 40px 0;
      flex: 0 0 40%;
      max-width: 40%;
    }

    & + & {
      position: absolute;
      bottom: 0;
      width: 136px;
      height: 136px;
      z-index: 1;
      left: calc(50% - 67px);

      @include device-desktop {
        position: static;
        padding: 40px;
        align-self: center;
        flex: 0 0 30%;
        max-width: 30%;
        height: 100%;
      }
    }
  }
}

.containerLink {
  display: block;
  cursor: pointer;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;

  @include device-desktop {
    flex-direction: row;
    padding: 0;
  }
}

.contentPadding {
  padding: 26px 30px 30px;

  @include device-desktop {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 40px;
  }
}

.light {
  background-color: $color-black;
  color: $color-white;
}

.dark {
  background-color: $color-white;
  color: $color-black;
}
