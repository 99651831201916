@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.wrapper {
  font-family: $font-primary;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $color-white;
  font-size: 13px;
  font-weight: 300;
  line-height: 25px;
  color: $color-black;
  width: 100%;
  max-width: $layout-max-width;
  padding: 55px 75px 27px 75px;

  a,
  button {
    color: $color-black;
    text-decoration: none;
    background-color: $color-transparent;
    display: flex;
    flex-direction: column;
    border: none;

    &:focus {
      outline: none;
    }
  }

  button {
    text-transform: capitalize;
    padding: 0;
    width: 100%;
    font-size: 13px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 1px;
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
  }
}

.header {
  text-transform: uppercase;
  padding: 7px 0 21px;
  margin: 0;
}

.linksWrapper {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;

  & > div {
    flex: 0 0 20%;
  }

  & select {
    background-color: $color-white;
    color: $color-black;
    border: none;
    min-width: 100px;
  }
}

.links {
  padding-bottom: 25px;
}

.visible {
  position: absolute;
}
