@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.accordionGroup {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-transform: none;
  width: 100%;
  font-family: $font-primary;
  overflow: hidden;

  @include device-desktop {
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.5px;
  }
  & button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    width: 100%;
    padding: 0;
    background-color: $color-white;

    &:focus {
      outline: none;
    }

    & svg {
      height: 10px;
      width: 10px;
      margin-left: 10px;
    }
  }
}

.header {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  font-family: $font-primary;
  padding: 0 0 40px;
  margin: 0;

  @include device-desktop {
    align-self: flex-start;
    font-size: 25px;
    line-height: 30px;
  }
}

.description {
  z-index: 1301;
  top: 60px;
  padding-bottom: 35px;
  width: 100%;
  background-color: $color-white;

  & [class*='RichTextParagraph'] {
    margin: 0;
    padding: 0;
    padding-right: 20px;
    font-family: $font-primary;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.08em;

    @include device-desktop {
      font-size: 13px;
      line-height: 25px;
    }
  }
}
