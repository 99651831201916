@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.header {
  display: flex;
  font-family: $font-secondary;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 44px;
  margin: 0;
  justify-content: center;

  @include device-desktop {
    align-self: flex-start;
    font-size: 25px;
    line-height: 30px;
  }
}

.pageHeader {
  font-family: $font-secondary;
  font-size: 24px;
  margin: 0 auto;
  text-transform: uppercase;
  padding: 45px 0 0;

  @include device-desktop {
    padding: 0;
    font-size: 50px;
  }
}

.description {
  padding: 24px 40px 0;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.24px;
  text-align: center;
}

.wrapper {
  display: flex;
  padding: 24px 30px 10px;
  margin: 0;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  width: 100%;
  white-space: nowrap;
  list-style-type: none;
  line-height: 20px;

  @include device-tablet {
    justify-content: center;
  }

  @include device-desktop {
    padding: 34px 40px 10px;
  }

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::after {
    position: absolute;
    content: '';
    right: 0;
    height: 50px;
    width: 70px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 0%, $color-white 100%);
    pointer-events: none;
  }
}

.menu {
  margin: 0 18px;
  text-transform: uppercase;
  font-size: 10px;

  @include device-desktop {
    font-size: 12px;
  }

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  & > a {
    display: flex;
    height: 100%;
    position: relative;
  }
}

.active > a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  background-color: $color-black;
  height: 1px;
  display: block;
  width: 100%;

  @include device-desktop {
    bottom: -4px;
  }
}
