@import 'assets/styles/mixins';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.container {
  height: 100vh;
  width: 100%;
  background: $color-black;
  position: fixed;
  display: flex;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background: $color-white;
  left: 0;
  height: 100vh;
  overflow-y: auto;

  @include device-desktop {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: row;
    max-width: 100vw;
    max-height: 100vh;
    width: 800px;
    height: 500px;
  }
}

.photo {
  display: block;
  width: 100%;
  min-height: 200px;

  @include device-desktop {
    min-width: 270px;
    height: 100%;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 23px;
  text-align: center;

  @include device-desktop {
    padding: 0 39px;
  }

  & > h3 {
    font-family: $font-secondary;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;

    @include device-desktop {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 28px;
    }
  }

  & > .paragraph * {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 28px;

    @include device-desktop {
      font-size: 13px;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }
}

.close {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}

.input {
  appearance: none;
  border-radius: 0;
  padding: 9px 20px;
  width: 240px;
  height: 40px;
  font-size: 13px;
  line-height: 25px;
  border: 1px solid #a4a4a4;

  &::placeholder {
    color: $color-gray-900;
  }
}

.consent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;

  @include device-desktop {
    margin-top: 15px;
  }

  & > .checkbox {
    appearance: none;
    border-radius: 0;
    min-width: 20px;
    height: 20px;
    border: 1px solid $color-black;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    margin-right: 14px;
    outline: none;
    position: relative;
    &:hover {
      cursor: pointer;
    }

    &.active {
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: $color-black;
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }
  }

  & p {
    margin: 0;
    font-size: 13px;
    line-height: 25px;
  }
}

.submitButton {
  margin-top: 45px;
  background: none;
  border: none;
  outline: none;

  @include device-desktop {
    margin-top: 35px;
  }

  &:focus {
    outline: 0;
  }
}

.message {
  height: 20px;
  font-size: 12px;
  margin: 0;
  padding: 0;
}
