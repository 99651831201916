@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/palette';

.wrapper {
  width: 100%;
  padding: 40px 23px;
  max-width: 840px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  font-family: $font-primary;
  color: $color-black;
  margin: 0 -10px;

  @include device-desktop {
    margin: 0 -30px;
  }

  & > * {
    flex: 0 1 100%;
    margin: 0;
    padding-right: 10px;
    padding-left: 10px;

    @include device-desktop {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  & > [class*='lazyload-wrapper'] {
    flex: 1 0 100%;
    padding-bottom: 22px;

    @include device-desktop {
      flex: 1 0 50%;
      padding-bottom: 31px;
    }
  }

  & > [class*='lazyload-wrapper'] + [class*='lazyload-wrapper'] {
    @include device-desktop {
      max-width: 100%;
    }
  }

  & > hr {
    margin: 24px 20px;

    @include device-desktop {
      margin: 32px 20px;
    }
  }

  & > blockquote,
  & > blockquote * {
    font-family: $font-primary;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;

    & > [class*='RichTextParagraph'] {
      margin: 0;
    }

    @include device-desktop {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }

  & > h1 {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.1em;
    font-family: $font-secondary;
    text-transform: uppercase;
    margin-bottom: 28px;

    @include device-desktop {
      font-size: 50px;
      line-height: 55px;
      letter-spacing: 0.08em;
      margin-bottom: 36px;
    }
  }

  & > h2 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    font-family: $font-primary;
    margin-bottom: 24px;

    @include device-desktop {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.03em;
      margin-bottom: 31px;
    }
  }

  & > h3 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    font-family: $font-secondary;
    margin-bottom: 22px;

    @include device-desktop {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.2em;
      margin-bottom: 31px;
    }
  }

  & > h4 {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    font-family: $font-secondary;
    margin-bottom: 20px;

    @include device-desktop {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.15em;
      margin-bottom: 28px;
    }
  }

  & > h5 {
    font-size: 12px;
    line-height: 20px;
    font-family: $font-primary;
    margin-bottom: 16px;
    letter-spacing: 0.05em;

    @include device-desktop {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }

  & > [class*='RichTextParagraph'] {
    margin: 0;
    margin-bottom: 25px;

    &,
    & > * {
      font-size: 13px;
      line-height: 25px;
      font-weight: 300;
      letter-spacing: 0.08em;
      font-family: $font-primary;
      color: $color-gray-900;
    }

    & a {
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.08em;
      line-height: 24px;
      text-decoration: underline;
      text-transform: uppercase;

      @include device-desktop {
        font-size: 15px;
      }
    }
  }
}

.cleaned [class*='RichTextParagraph']:empty {
  display: none;
}

.header {
  font-weight: 600;
  padding-bottom: 22px;
  font-size: 16px;
  line-height: 24px;

  @include device-desktop {
    padding-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
  }
}
