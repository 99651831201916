@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.container {
  width: 100%;
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include device-desktop {
    flex-direction: row;
  }
}

.inner {
  &.big {
    padding: 0;
  }

  &.small {
    padding: 60px;

    @include device-desktop {
      padding: 120px;
    }
  }
}

.contentPadding {
  padding: 15px 60px;

  @include device-desktop {
    padding: 20px 118px;
  }
}

.light {
  background-color: $color-black;
}

.dark {
  background-color: $color-white;
}
