@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/palette';

.header {
  display: flex;
  justify-content: space-between;
}

.relatedCategory {
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: $font-small-desktop;
  line-height: $font-huge-desktop;
  color: $black;
}

.link {
  font-size: 11px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: 0.07em;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: $color-transparent;
  color: $black;
  display: inline-block;
  height: auto;
  width: auto;
  position: relative;
  overflow: hidden;
}

.link:hover {
  text-decoration: underline;
}

.link:hover:before {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.link:hover:after {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0ms;
  -ms-transform: translateX(105%);
  transform: translateX(105%);
}

.link:before,
.link:after {
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 0;
  background-color: $black;
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
}

.link:before {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0ms;
  -ms-transform: translateX(-105%);
  transform: translateX(-105%);
}

.headerIcon img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
