@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/palette';

.ul {
  list-style-position: inside;
  list-style-type: disc;
}

.withSpace {
  padding-bottom: 0;
}
