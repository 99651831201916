@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/palette';

.li {
  width: 100%;

  & > * {
    display: inline;
  }
}
