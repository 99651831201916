@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.wrapper {
  display: flex;
  font-family: $font-primary;
  letter-spacing: 0.25px;
  flex-direction: column;
  align-items: center;
  background-color: $color-white;
  color: $color-black;
  width: 100%;
  padding: 0 0 30px 0;

  a,
  .copyright,
  button {
    display: flex;
    color: $color-black;
    text-decoration: none;
    background-color: $color-transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    &:focus {
      outline: none;
    }
  }

  button {
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    width: 100%;
  }

  .linksWrapper {
    text-transform: uppercase;
    .links {
      padding: 30px 0;
    }
    .link {
      font-size: 12px;
      line-height: 20px;
      padding: 10px 0;
      display: flex;
      justify-content: center;
    }
  }

  .socialMedia {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 9px 0 39px 0;

    a {
      padding: 0 10px;
      height: 31px;

      &:empty {
        display: none;
      }
    }
  }
}
