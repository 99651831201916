@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.wrapper {
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 0;
  color: currentColor;

  @include device-desktop {
    display: flex;
  }
}
.choosen {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  width: 100%;
  & > svg {
    margin-top: 2px;
  }
}

.container {
  position: absolute;
  bottom: 100%;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  background-color: $color-white;
  color: $color-black;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
