@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';
@import 'assets/styles/animations';

.container {
  position: fixed;
  box-shadow: 0 0 20px 10px rgb(255 255 255 / 20%);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $color-black;
  color: $color-white;
  padding: 26px 30px;
  z-index: 1302;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.45px;

  @include device-desktop {
    flex-direction: row;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}

.content {
  font-family: $font-primary;
  font-size: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 23px;
  text-align: center;
  max-width: 666px;
  margin: 0 auto;

  @include device-desktop {
    align-items: flex-start;
    text-align: left;
    margin: 0 auto 0 0;
  }

  & a {
    color: $color-white;
  }

  & p {
    margin: 0;
  }
}

.header {
  font-family: $font-secondary;
  padding-bottom: 14px;
  font-weight: 500;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: underline;
  background-color: $color-transparent;
  box-shadow: none;
  border: 1px solid $color-white;
  padding: 10px;
  color: $color-white;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.41px;
  margin: 0 auto;
  margin-top: 17px;

  @include device-desktop {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.47px;
    padding: 10px 20px;
    margin: 0;
  }
}

.warning {
  background-color: $color-red-400;
  color: $color-black;

  & .consent {
    color: $color-white;
  }
}
