@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import '../../../assets/styles/palette';

.productInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  flex-direction: column;
  padding: 8px 0;
  color: $color-black;

  @include device-desktop {
    line-height: 18px;
    letter-spacing: 0.454545px;
    padding: 8px 0 20px 0;
  }
}

.infoRow {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 11px;
  align-items: flex-start;
  font-weight: 500;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  width: 50%;

  & img {
    height: 9px;
    margin-right: 6px;
    margin-bottom: 6px;
    filter: grayscale(100%);

    @include device-desktop {
      height: auto;
      max-height: 41px;
      margin-right: 10px;
    }
  }
}

.labelText {
  font-family: $font-primary;
  font-style: normal;
  color: $black;
  width: 100%;
}

.soldOut {
  color: #949494;
  .labelText {
    color: #949494;
  }
}
