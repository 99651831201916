@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.container {
  width: 100%;
  position: relative;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 10px;

  @include device-desktop {
    padding: 20px;
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;

  @include device-desktop {
    padding: 40px;
  }

  &:empty {
    display: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;

  @include device-desktop {
    padding: 20px;
  }
}

.contentPadding {
  padding: 26px 30px 30px;

  @include device-desktop {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 40px;
  }
}

.light {
  background-color: $color-black;
}

.dark {
  background-color: $color-white;
}
