@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/palette';

.ol {
  list-style: decimal;
  list-style-position: inside;
}

.withSpace {
  padding-bottom: 0;
}
