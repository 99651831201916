@import './assets/styles/mixins';

.shortText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 24px;
  font-size: 24px;
  line-height: 120%;

  @include device-desktop {
    padding: 32px;
  }

  a {
    text-decoration: none;
    color: $color-black;
    line-height: 120%;
  }
}
.top {
  align-items: flex-start;
}
.middle {
  align-items: center;
}
.bottom {
  align-items: flex-end;
}
.left {
  justify-content: flex-start;
}
.center {
  justify-content: center;
  text-align: center;
}
.right {
  justify-content: flex-end;
  text-align: right;
}
