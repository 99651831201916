@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/palette';
@import 'assets/styles/animations';

.wrapper {
  display: none;
  margin: 0 auto 0 35px;
  height: 100%;

  @include device-desktop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.list {
  display: flex;
  list-style: none;
  padding: 0;
  height: 100%;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  & > a {
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 13px 35px;
    color: inherit;
    height: 100%;

    & > span {
      display: flex;
    }
  }

  &:hover {
    & > a {
      font-weight: 600;
    }
  }
}

.active {
  @include device-desktop {
    font-weight: 600;
  }
}

.menu {
  position: absolute;
  width: 100%;
  background-color: white;
  color: black;
  left: 0;
  top: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .item & {
    display: none;
  }

  .item:hover & {
    display: flex;
    animation: menu-fade-in 0.2s ease-out forwards;
  }
}

.columns {
  width: 100%;
  max-width: $layout-max-width;
  margin: 0 auto 13px;
  padding: 0;
  display: flex;

  &:empty {
    display: none;
  }

  & .menuItem {
    text-transform: uppercase;
  }
}

.column {
  display: flex;
  align-items: center;
  margin-right: 50px;
  font-size: 12px;
  line-height: 25px;

  &:hover {
    & > a {
      font-weight: 600;
    }
  }

  &:first-of-type {
    margin-left: 40px;
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  padding: 0;
}
