@import 'assets/styles/mixins';
@import 'assets/styles/variables';
@import 'assets/styles/palette';
@import 'assets/styles/animations';

.wrapper {
  display: none;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 35;
  padding-right: 8px;
  padding-left: 12px;
  margin-left: 0;
  color: currentColor;
  margin-left: auto;

  @include device-desktop {
    display: flex;
  }
}

.transparent {
  color: $color-transparent;
}

.container {
  position: absolute;
  top: 50px;
  width: 180px;
  background-color: $color-white;
  color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 23px 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  animation: header-currency-slide 0.2s ease-out forwards;

  & > div {
    position: relative;
    transition: background-color 0.3s ease-out;
    background-color: $color-transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

.choosen {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;

  & > svg {
    margin-top: 2px;
  }
}
