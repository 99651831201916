@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/animations';

.content {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.wrapper {
  flex: 0 0 auto;
}

.dark {
  & .title,
  & .link,
  & .link a,
  & .description {
    color: $color-black;
  }
}

.light {
  & .title,
  & .link,
  & .link a,
  & .description {
    color: $color-white;
  }
}

.big {
  & .wrapper {
    max-width: auto;

    @include device-desktop {
      max-width: 820px;
    }
  }

  & .title {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;

    @include device-desktop {
      font-size: 25px;
      line-height: 30px;
    }
  }

  & .description {
    margin-top: 14px;

    @include device-desktop {
      margin-top: 25px;
    }

    & > [class*='RichTextParagraph'] {
      font-family: $font-primary;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.08em;

      @include device-desktop {
        font-size: 13px;
        line-height: 25px;
      }
    }

    & > p {
      margin: 0;
      padding: 0;
    }
  }

  & .link {
    @include scale-link-arrow;

    margin-top: 18px;

    @include device-desktop {
      margin-top: 26px;
    }

    & svg {
      width: 20px;
      height: 20px;

      @include device-desktop {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@include device-mobile-and-tablet {
  .mobile_top {
    justify-content: flex-start;
  }

  .mobile_middle {
    justify-content: center;
  }

  .mobile_bottom {
    justify-content: flex-end;
  }

  .mobile_left {
    align-items: flex-start;
  }

  .mobile_center {
    align-items: center;
    text-align: center;
  }

  .mobile_right {
    align-items: flex-end;
    text-align: right;
  }
}

@include device-desktop {
  .desktop_top {
    justify-content: flex-start;
  }

  .desktop_middle {
    justify-content: center;
  }

  .desktop_bottom {
    justify-content: flex-end;
  }

  .desktop_left {
    align-items: flex-start;
  }

  .desktop_center {
    align-items: center;
    text-align: center;
  }

  .desktop_right {
    align-items: flex-end;
    text-align: right;
  }
}
