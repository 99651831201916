@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import '../../../assets/styles/palette';

.form {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
  transition: 0.2s;
}

.input {
  height: inherit;
  width: auto;
  transition: 0.2s;
  background-color: $color-transparent;
  border: none;
  text-decoration: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  padding: 0;
  color: $color-gray-900;

  &::placeholder {
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.button {
  padding: 0;
  border: none;
  height: 50px;
  width: 64px;
  position: relative;
  cursor: pointer;
  background-color: $color-transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;

  &:focus,
  &:active {
    outline: none;
  }

  & > svg {
    width: 15px;
    height: 15px;
  }
}
