@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.wrapper {
  height: 50px;
  width: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 35;

  @include device-desktop {
    display: flex;
  }
}

.active {
  margin-left: auto;
}

.button {
  @include reset-button;

  width: 100%;
  height: 100%;
  cursor: pointer;
  color: inherit;

  & > svg {
    width: 15px;
    height: 15px;
  }
}

.input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 50px);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  color: currentColor;
  border: none;
  background-color: $color-transparent;
  display: none;
  height: 100%;
  opacity: 0;
  transform: translate(0, 50%);

  &::placeholder {
    font-family: $font-secondary;
    color: $color-black;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  .active & {
    display: flex;
    animation: fade-in 0.2s ease-out forwards;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(0, 50%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
