@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';

.caption {
  font-family: $font-primary;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.08em;
  padding: 0;
  margin: 15px 0 0;
  color: $color-black;

  @include device-desktop {
    font-size: 12px;
    line-height: 20px;
  }
}
