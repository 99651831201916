@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/palette';

.blockquote {
  background: $color-transparent;
  margin: 0;

  &::before {
    content: none;
  }
}
