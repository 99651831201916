@import 'assets/styles/extends';
@import 'assets/styles/palette';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-height: 100%;
}

.shopTheLook {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  transition: transform 0.2s ease-out;
  transform: scale(1);
  aspect-ratio: 0.7;

  & > div {
    width: 100%;
  }

  &:hover {
    transform: scale(0.95);
  }
}

.preload {
  max-width: 100%;

  &.visible {
    display: block;
  }
}

.lazyWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.imagePlaceholder {
  width: 60%;
  display: flex;
  align-self: center;
}

.secondImage,
.firstImage {
  overflow: hidden;
  width: 100%;
  max-height: 100%;
}

.wrapper {
  width: 100%;
  position: relative;

  &:hover {
    // when hovering the first image should be hidden and the second one should be visible
    // this way image flipping is being handled by CSS and react components are not rerendering all over again
    .secondImage {
      display: block;
    }

    .firstImage:not(.noHover) {
      position: absolute;
      opacity: 0;
      filter: blur(100px);
      transition: all 0.5s ease-out;
    }
  }

  // in normal state second image should be hidden
  .secondImage {
    display: none;
  }
}
