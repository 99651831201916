@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 56.25%;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.light {
  background-color: $color-black;
}

.dark {
  background-color: $color-white;
}

.shadow::after {
  content: '';
  position: absolute;
  display: block;

  @include device-mobile-and-tablet {
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, $color-black 100%);
  }

  @include device-desktop {
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.0001) 0%, $color-black 100%);
  }
}
