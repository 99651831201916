@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.container {
  position: relative;
  &.menuOpened {
    position: fixed;

    @include device-desktop {
      position: static;
    }
  }
  & > .portal {
    z-index: 1310;
  }
}
