@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.wrapper {
  width: 100%;
  height: auto;

  & > [class*='lazyload-wrapper'] {
    display: block;
    width: 100%;
    height: 100%;

    & > div {
      display: block;
      width: 100%;
      height: 100%;

      .container[class*='fade-appear'] {
        opacity: 0;
        z-index: 1;
        transition: opacity 500ms linear;
        width: 100%;
        height: 100%;
      }

      .container[class*='fade-appear-done'] {
        opacity: 1;
      }
    }
  }
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
