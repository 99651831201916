@import '../../assets/styles/mixins';
@import 'assets/styles/variables';

.wrapper {
  position: relative;
  font-family: $font-secondary;
  background-color: $color-transparent;

  & > button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0;
    color: $black;

    &:focus {
      outline: none;
    }

    & svg {
      height: 10px;
      width: 10px;
    }
  }
  .description {
    padding: 0;
  }
}

.title {
  font-family: $font-secondary;
  margin: 0;
  font-weight: 400;
  text-align: left;
  width: calc(100% - 20px);
}

.vertical {
  width: 100%;
  position: relative;
  background-color: $color-transparent;

  @include device-desktop {
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  & > button {
    background-color: $color-transparent;
    text-transform: uppercase;
    padding: 15px 0;
    position: relative;

    @include device-desktop {
      padding: 21px 40px;
    }
  }
}

.horizontal {
  overflow: hidden;

  & .title {
    padding: 15px 0;
    font-size: 12px;
    line-height: 20px;

    @include device-desktop {
      font-size: 13px;
      padding: 20px 0;
      line-height: 25px;
    }
  }
}

.chevron {
  transition: rotate 0.2s ease-in-out;
}

.chevronExpanded {
  rotate: 180deg;
}
