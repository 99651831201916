@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';
@import 'assets/styles/animations';

.wrapper {
  height: 60px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 35;

  @include device-desktop {
    height: 50px;
    width: 50px;
  }
}

.cart {
  @include reset-button;

  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 15px;
    height: 15px;
  }
}

.minicartQuantity {
  position: absolute;
  top: 13px;
  left: 0px;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $color-red-400;
  color: white;
  font-size: 10px;
  line-height: 18px;
  animation: bag-shake 0.2s ease-in-out forwards;

  @include device-desktop {
    top: 8px;
    left: 3px;
  }
}

.button {
  display: none;

  @include device-desktop {
    display: flex;
  }
}

.link {
  @include device-desktop {
    display: none;
  }
}
