@import 'assets/styles/palette';

.picture {
  display: block;
  width: 100%;
  height: 100%;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picture[class*='fade-appear'],
.image[class*='fade-appear'] {
  opacity: 0;
  z-index: 1;
  transition: opacity 500ms linear;
  object-fit: cover;
}

.picture[class*='fade-appear-done'],
.image[class*='fade-appear-done'] {
  opacity: 1;
}

.placeholder {
  background-color: $color-black;
}
