@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/mixins';
@import '../../../assets/styles/palette';
@import 'assets/styles/variables';

.wrapper {
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.32px;
  text-transform: lowercase;

  @include device-desktop {
    font-size: 12px;
    line-height: 18px;
  }

  &.discount {
    .current {
      color: #e7422c;
    }
  }
}

.current {
  color: inherit;
  text-transform: uppercase;
}

.original {
  padding: 0 8px;
  text-decoration: line-through;
}
