@import 'assets/styles/extends';
@import 'assets/styles/palette';

.grid {
  display: grid;
  margin: 0 20px;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @include device-tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include device-desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  & > div {
    & > div[class*='slide-appear'] {
      transition: translate 100ms linear;
      transform: translate(0, -10px);
    }

    & > div[class*='slide-appear-done'] {
      transform: translate(0, 0);
    }
  }
}

.dividerWrapper {
  margin: 0;
}

.noProducts {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & > h2 {
    font-family: $font-primary;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
  }
}
