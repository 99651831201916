@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.wrapper {
  display: flex;

  a {
    text-decoration: none;
  }
}
